import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPalette, 
  faLeaf, 
  faChevronDown,
  faHandshakeSimple,
  faStar
} from '@fortawesome/free-solid-svg-icons';

type FAQItem = {
  question: string;
  answer: string;
};

const About: React.FC = () => {
  const faqs: FAQItem[] = [
    {
      question: "Where are you based?",
      answer: "Our creative workshop is nestled in the heart of Rome, Italy, where each piece is designed with passion and precision."
    },
    {
      question: "Do you ship overseas?",
      answer: "We're expanding our reach! Currently, we ship across the EU and offer select pieces to Mexico. If you're dreaming of a custom piece and live outside these regions, don't worry—we can arrange personalized shipping just for you."
    },
    {
      question: "How long does shipping take?",
      answer: "Our pieces embark on their journey every afternoon and typically arrive within 5 to 7 days across the EU. For custom arrangements, shipping times may vary, but we'll keep you informed every step of the way."
    },
    {
      question: "Can I order a custom piece?",
      answer: "Absolutely! Custom pieces are the essence of our artistry. They take time, care, and a personal touch. Simply email us or drop a DM on Instagram, and let's craft something extraordinary together."
    },
    {
      question: "What materials do you work with?",
      answer: "Our collections feature silver and golden-plated brass, perfect for a timeless look. For custom projects, we also work with gold to bring your unique vision to life."
    },
    {
      question: "Do you have eco-friendly options?",
      answer: "Sustainability matters to us! Many of our designs use recycled metals and ethically sourced materials. Custom orders can also incorporate eco-friendly choices—just let us know!"
    },
    {
      question: "What inspires your designs?",
      answer: "Each piece tells a story, drawing from mythical creatures, enigmatic textures, and timeless themes. Our jewelry is for those who embrace the unusual and cherish the captivating."
    }
  ];

  return (
    <div className="relative w-screen min-h-screen">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-brand-blue bg-opacity-50 dark:bg-opacity-100">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-20 -left-20 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          <div className="absolute top-40 right-20 w-80 h-80 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          <div className="absolute bottom-20 left-1/3 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
        </div>
      </div>

      <div className="relative py-20 px-4">
        <div className="max-w-[80vw] mx-auto">
          {/* Hero Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="grid md:grid-cols-2 gap-8 items-center mb-20"
          >
            <div className="space-y-6">
              <h1 className="text-4xl md:text-5xl font-bold text-black dark:text-white">
                About Me
              </h1>
              <p className="text-lg text-black/80 dark:text-white/80">
                As a jewelry designer, I blend artistry with innovation to create pieces that tell stories. 
                Each creation is a testament to my passion for unique design and sustainable craftsmanship, 
                embracing both traditional techniques and modern aesthetics.
              </p>
            </div>
            <motion.div 
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-white/10 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10"
            >
              <img 
                src="/camila-parente.jpg" 
                alt="Camila Parente" 
                className="w-full h-full object-cover" 
              />
            </motion.div>
          </motion.div>

          {/* Values Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="space-y-12 mb-20"
          >
            <h3 className="text-3xl font-bold text-center text-black dark:text-white mb-12">
              Our Values
            </h3>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: faHandshakeSimple,
                  title: "Ethical Craftsmanship",
                  description: "Committed to ethical and sustainable jewelry production, using precious metals without reliance on conflict materials or unethical mining practices."
                },
                {
                  icon: faPalette,
                  title: "Artistry and Design",
                  description: "Blending modern techniques learned in Rome with traditional Mexican jewelry-making to create truly unique, artistic pieces."
                },
                {
                  icon: faLeaf,
                  title: "Nature and Organic Inspiration",
                  description: "Our designs draw inspiration from nature's organic shapes and forms, creating pieces that reflect the beauty of the natural world."
                },
                {
                  icon: faStar,
                  title: "Creativity and Self-Expression",
                  description: "Celebrating creativity through one-of-a-kind jewelry designs that enable personal style expression."
                }
              ].map((value, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 + (index * 0.1) }}
                  className="group bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 p-8 space-y-4"
                >
                  <div className="text-black dark:text-white mb-4">
                    <FontAwesomeIcon icon={value.icon} className="h-8 w-8" />
                  </div>
                  <h4 className="text-xl font-semibold text-black dark:text-white">
                    {value.title}
                  </h4>
                  <p className="text-black dark:text-white/80">
                    {value.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* FAQ Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            className="space-y-8"
          >
            <h3 className="text-3xl font-bold text-center text-black dark:text-white mb-12">
              Frequently Asked Questions
            </h3>
            <div className="max-w-3xl mx-auto space-y-4">
              {faqs.map((faq, index) => {
                const [isOpen, setIsOpen] = useState(false);
                return (
                  <motion.div 
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1 + (index * 0.1) }}
                    className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl overflow-hidden border border-white/10"
                  >
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      className="w-full flex justify-between items-center p-4 text-left hover:bg-white/5 transition-colors"
                    >
                      <span className="text-lg font-semibold text-black dark:text-white">
                        {faq.question}
                      </span>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`text-black dark:text-white transform transition-transform duration-200 ${
                          isOpen ? 'rotate-180' : ''
                        }`}
                      />
                    </button>
                    <div
                      className={`overflow-hidden transition-all duration-200 ease-in-out ${
                        isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                      }`}
                    >
                      <p className="p-4 text-black/80 dark:text-white/80">
                        {faq.answer}
                      </p>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;