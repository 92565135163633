import React from 'react';
import { Link } from 'react-router-dom';

interface LogoProps {
  switchable?: boolean;
}

const Logo: React.FC<LogoProps> = ({ switchable = true }) => {
  return (
    <Link to="/" className="flex items-center">
      {switchable ? (
        <>
          {/* Mobile Logo */}
          <img
            src="/Logo-Small.svg"
            alt="Logo"
            className="block md:hidden h-8 dark:invert"
          />
          {/* Desktop Logo */}
          <img
            src="/Logo-Black-Simple.svg"
            alt="Logo"
            className="hidden md:block h-12 dark:invert"
          />
        </>
      ) : (
        <img
          src="/Logo-Black-Simple.svg"
          alt="Logo"
          className="h-12 dark:invert"
        />
      )}
    </Link>
  );
};

export default Logo;