import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import products from '../../data/products.json';

interface Category {
  name: string;
  description: string;
  filterName: string;
  link: string;
}

const categories: Category[] = [
  {
    name: "Earrings and Hoops",
    description: "Explore our unique collection of earrings and hoops.",
    filterName: "Earrings",
    link: "/categories/earrings-and-hoops"
  },
  {
    name: "Rings",
    description: "Discover our exquisite range of rings.",
    filterName: "Rings",
    link: "/categories/rings"
  },
  {
    name: "Unique Finds",
    description: "Find one-of-a-kind pieces in our unique finds.",
    filterName: "Pendant",  // You can adjust this filter as needed
    link: "/categories/unique-finds"
  }
];

const CategoriesSection: React.FC = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [categoryImages, setCategoryImages] = useState<string[][]>([]);

  useEffect(() => {
    // Get and store images once when component mounts
    const getInitialImages = () => {
      const allImages = categories.map(category => {
        let categoryProducts;
        
        if (category.filterName === "Earrings") {
          categoryProducts = products.filter(p => 
            ["Earrings", "Hoops", "Earring (Singular)", "Hoop (Singular)"].includes(p.category)
          );
        } else if (category.filterName === "Rings") {
          categoryProducts = products.filter(p => p.category === category.filterName);
        } else {
          categoryProducts = products.filter(p => 
            !["Earrings", "Hoops", "Earring (Singular)", "Hoop (Singular)", "Rings"].includes(p.category)
          );
        }

        const shuffled = [...categoryProducts].sort(() => 0.5 - Math.random());
        const images = shuffled.slice(0, 3)
          .map(p => p.mainImage)
          .filter((img): img is string => !!img);
        while (images.length < 3) {
          images.push(images[images.length - 1] || '/placeholder-image.jpg');
        }
        return images;
      });
      setCategoryImages(allImages);
    };

    getInitialImages();
  }, []); // Only run once on mount

  return (
    <section className="py-16 md:py-24">
      <div className="text-center mb-12">
        <h2 className="text-3xl md:text-4xl font-bold text-black dark:text-white mb-4">
          Find Something Special
        </h2>
        <p className="text-lg text-black/80 dark:text-white/80">
          Discover pieces that speak to your style
        </p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 w-full">
        {categories.map((category, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <Link 
              to={category.link}
              className="relative block"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="h-80 md:h-[500px] w-full overflow-hidden relative">
                {categoryImages[index]?.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={category.name}
                    className={`w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-500 ${
                      hoveredIndex === index && imgIndex !== 0 ? 'opacity-0' : 'opacity-100'
                    }`}
                    style={{ 
                      transitionDelay: hoveredIndex === index ? `${imgIndex * 200}ms` : '0ms'
                    }}
                  />
                ))}
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-black/40 to-black/70 pt-20 pb-8 px-4 text-center text-white">
                <h3 className="text-2xl font-bold">{category.name}</h3>
                <p className="mt-2">{category.description}</p>
              </div>
            </Link>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default CategoriesSection;