import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo';
import CartDropdown from './CartDropdown';
import { useUI } from '../context/UIContext';

const Header: React.FC = () => {
  const { isCartOpen, setIsCartOpen } = useUI();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const cartRef = useRef<HTMLDivElement>(null);
  const cartButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      
      // Check if click is within cart area or its interactive elements
      const isCartArea = cartRef.current?.contains(target) || 
                        cartButtonRef.current?.contains(target) ||
                        target.closest('.cart-dropdown') ||
                        target.closest('.quantity-controls') ||
                        target.closest('.remove-button');

      // Check for navigation elements
      const isCheckoutButton = target.closest('.checkout-button');
      const isProductLink = target.closest('.product-link');

      // Close if clicking outside cart area OR clicking navigation elements
      if (isCartOpen && (!isCartArea || isCheckoutButton || isProductLink)) {
        setIsCartOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCartOpen]);

  // Handle body overflow
  useEffect(() => {
    if ((isCartOpen || isOpen) && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isCartOpen, isOpen, isMobile]);

  const menuVariants = {
    closed: { opacity: 0, x: "100%" },
    open: { opacity: 1, x: 0 }
  };

  return (
    <header className="dark:text-white p-4 my-2 top-0 z-50 container">
      <div className="mx-auto flex justify-between items-center md:w-screen md:px-20">
        <motion.div whileHover={{ scale: 1.1 }}>
          <Logo />
        </motion.div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-4 items-center relative z-50">
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="/shop" className="transition-transform block">Shop</Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="/about" className="transition-transform block">About</Link>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="/contact" className="transition-transform block">Contact</Link>
          </motion.div>
            {/* <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="/login" className="transition-transform block">
              <button 
              aria-label="Login" 
              className="flex items-center justify-center px-4 py-2 h-10 rounded-lg bg-brand-dark dark:bg-brand-white text-text-light dark:text-text-dark transition-transform"
              >
              <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
              Login
              </button>
            </Link>
            </motion.div> */}
          {/* Desktop Cart Button and Dropdown */}
          <div className="relative" ref={cartRef}>
            <motion.button 
              ref={cartButtonRef}
              whileHover={{ scale: 1.1 }}
              aria-label="Cart" 
              className="flex items-center justify-center px-4 py-2 h-10 rounded-lg bg-brand-blue transition-transform text-text-light"
              onClick={() => setIsCartOpen(!isCartOpen)}
            >
              <FontAwesomeIcon icon={faShoppingCart} size="sm" />
            </motion.button>

            <AnimatePresence>
              {isCartOpen && (
                <motion.div 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  className={`
                    ${isMobile ? 'fixed inset-0 z-60 bg-white dark:bg-black' : 
                    'absolute top-full right-0 mt-2 w-96 bg-white dark:bg-black rounded-lg shadow-xl z-60'}
                  `}
                >
                  <CartDropdown isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </nav>

        {/* Mobile Controls */}
        <div className="flex items-center gap-4 md:hidden">
          {/* Mobile Cart Button */}
          <motion.div whileHover={{ scale: 1.1 }} className="relative z-[70]" ref={cartRef}>
            <button 
              ref={cartButtonRef}
              aria-label="Cart" 
              className="z-[70] flex items-center justify-center px-4 py-2 h-10 rounded-lg bg-brand-blue text-white"
              onClick={() => setIsCartOpen(!isCartOpen)}
            >
              <FontAwesomeIcon icon={faShoppingCart} className="h-5 w-5" />
            </button>
            <div className="fixed md:absolute left-0 right-0 w-screen md:w-96 md:left-auto top-20 md:top-full z-[70]">
              <CartDropdown isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
            </div>
          </motion.div>

          {/* Mobile Hamburger */}
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="z-[10] text-brand-dark dark:text-brand-white"
          >
            <FontAwesomeIcon icon={isOpen ? faXmark : faBars} className="h-5 w-5" />
          </button>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              className="fixed inset-0 bg-brand-sand dark:bg-brand-charcoal z-30 md:hidden"
            >
              <div className="flex flex-col items-center justify-center h-full space-y-8">
                {/* Logo at top */}
                <motion.div 
                  whileHover={{ scale: 1.1 }}
                  className="absolute top-6 left-1/2 transform -translate-x-1/2"
                >
                  <Logo />
                </motion.div>

                {/* Navigation Items */}
                <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link to="/" onClick={() => setIsOpen(false)} className="text-brand-dark dark:text-brand-white text-xl">
                    Home
                  </Link>
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link to="/shop" onClick={() => setIsOpen(false)} className="text-brand-dark dark:text-brand-white text-xl">
                    Shop
                  </Link>
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link to="/about" onClick={() => setIsOpen(false)} className="text-brand-dark dark:text-brand-white text-xl">
                    About
                  </Link>
                </motion.div>
                <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link to="/contact" onClick={() => setIsOpen(false)} className="text-brand-dark dark:text-brand-white text-xl">
                    Contact
                  </Link>
                </motion.div>
                {/* <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link to="/login" onClick={() => setIsOpen(false)} className="block">
                  <button className="w-full flex items-center justify-center px-4 py-2 rounded-lg bg-brand-dark dark:bg-brand-white text-text-light dark:text-text-dark">
                    <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                    Login
                  </button>
                  </Link>
                </motion.div> */}
                <motion.div whileHover={{ scale: 1.1 }} className="w-32 text-center">
                  <Link 
                    to="/cart" 
                    onClick={() => {
                      setIsOpen(false);
                      setIsCartOpen(false);
                    }} 
                    className="flex items-center justify-center px-4 py-2 rounded-lg bg-brand-blue text-white"
                  >
                    <FontAwesomeIcon icon={faShoppingCart} className="mr-2" />
                    Cart
                  </Link>
                </motion.div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;