// src/components/HeroSection.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const HeroSection: React.FC = () => {


  const blurVariants = {
    hidden: { filter: 'blur(0px)' },
    visible: { filter: 'blur(5px)', transition: { delay: 0.33, duration: 0.25 } }
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 0.25 } }
  };

  return (
    <div className="relative w-full">
      {/* Background Container */}
      <motion.div 
        className="
          absolute top-0 left-1/2 transform -translate-x-1/2 scale-150 md:scale-100
          min-w-[100vw] min-h-[60vh] 
          bg-cover bg-left md:bg-center -z-10 -mt-[89px]
        " 
        style={{ backgroundImage: "url('/dsc02784-2.jpg')" }}
        initial="hidden"
        animate="visible"
        variants={blurVariants}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-white/50 to-transparent dark:from-black/50" />
      </motion.div>

      {/* Hero Content */}
      <section className="
        relative z-10 min-h-[60vh] md:min-h-[50vh] flex items-center md:-mt-[20px]
        [@media(max-height:500px)_and_(orientation:landscape)]:min-h-[80vh]
      ">
        <div className="container mx-auto px-4 max-w-[80vw] md:max-w-[50vw]">
          <motion.h1 
            className="
              text-3xl md:text-5xl font-bold mb-4 -mt-20
              [@media(max-height:500px)_and_(orientation:landscape)]:text-2xl
              [@media(max-height:500px)_and_(orientation:landscape)]:mt-20
              [@media(max-height:500px)_and_(orientation:landscape)]:mb-2
              text-dark dark:text-white
              drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]
              dark:drop-shadow-[0_2px_4px_rgba(0,0,0,0.4)]
            "
            initial="hidden"
            animate="visible"
            variants={textVariants}
          >
            A jewelry project that embraces the unusual and the enigmatic.
          </motion.h1>
          <motion.h2 
            className="
              text-xl mb-8
              [@media(max-height:500px)_and_(orientation:landscape)]:text-base
              [@media(max-height:500px)_and_(orientation:landscape)]:mb-4
              text-dark dark:text-white
              drop-shadow-[0_2px_4px_rgba(0,0,0,0.4)]
              dark:drop-shadow-[0_2px_4px_rgba(0,0,0,0.5)]
            "
            initial="hidden"
            animate="visible"
            variants={textVariants}
          >
            Inspired by mythical creatures, rich textures, and captivating themes, each piece carries a unique character and blends diverse styles beyond trends.
          </motion.h2>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0, transition: { delay: 1, duration: 0.75 } }
            }}
          >
            <Link 
              to="/shop" 
              className="
                inline-block bg-brand-blue text-white py-2 px-4 rounded-lg 
                hover:bg-brand-dark transition-colors
                [@media(max-height:500px)_and_(orientation:landscape)]:py-1
                [@media(max-height:500px)_and_(orientation:landscape)]:text-sm
              "
            >
              Shop Now
            </Link>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;