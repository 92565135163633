import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Product, ProductVariant } from '../../types/product-variant';
import CartActions from './CartActions';
import { useState } from 'react';

interface ProductInfoProps {
    product: Product;
    selectedVariant: ProductVariant | null;
    isAdded: boolean;
    localQuantity: number;
    onAddToCart: () => void;
    onQuantityChange: (quantity: number) => void;
    showVariantError: boolean;
    onVariantChange: (variantId: string) => void;
    inStock?: boolean;
}

const ProductInfo = ({
    product,
    selectedVariant,
    isAdded,
    localQuantity,
    onAddToCart,
    onQuantityChange,
    showVariantError,
    onVariantChange
}: ProductInfoProps) => {
    const [showWaitlistModal, setShowWaitlistModal] = useState(false);
    const [email, setEmail] = useState('');
    const activePrice = selectedVariant?.price ?? product?.price ?? product?.basePrice ?? 0;
    const isProductInStock = selectedVariant ? selectedVariant.inStock : (product.inStock ?? true);

    const handleWaitlistSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle waitlist signup logic here
        console.log('Waitlist signup:', email, product.id);
        setEmail('');
        setShowWaitlistModal(false);
    };

    const MaterialTag = () => {
        if (product.variants) {
            return selectedVariant ? (
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    {selectedVariant.material}
                </span>
            ) : (
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    Multiple Materials Available
                </span>
            );
        }
        
        return product.material ? (
            <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                {product.material}
            </span>
        ) : null;
    };

    const VariantSelector = () => (
        product?.variants && (
            <div className="mb-6">
                <label className="block text-black dark:text-white mb-2">
                    Select Material:
                </label>
                <select 
                    className="w-full p-2 border rounded-lg bg-white dark:bg-brand-charcoal text-black dark:text-white"
                    onChange={(e) => onVariantChange(e.target.value)}
                    value={selectedVariant?.id || ''}
                >
                    <option value="">Select material</option>
                    {product.variants.map(variant => (
                        <option key={variant.id} value={variant.id}>
                            {variant.material} - €{variant.price.toFixed(2)}
                        </option>
                    ))}
                </select>
            </div>
        )
    );

    return (
        <div className="space-y-6 pb-24 md:pb-0">
            <h1 className="text-4xl font-bold text-black dark:text-white">
                {product.name}
            </h1>
            {product.oneOfKind && (
                <div className="flex items-center gap-2 text-brand-blue">
                    <FontAwesomeIcon icon={faStar} className="h-4 w-4" />
                    <p className="font-semibold text-lg">
                        One of a Kind Piece
                    </p>
                </div>
            )}
            <VariantSelector />
            <p className="text-xl text-black dark:text-white">
                €{activePrice.toFixed(2)}
            </p>
            
            {!isProductInStock && (
                <div className="text-red-500 font-semibold mb-4">
                    Out of Stock
                </div>
            )}

            <CartActions
                product={product}
                isAdded={isAdded}
                localQuantity={localQuantity}
                onAddToCart={onAddToCart}
                onQuantityChange={onQuantityChange}
                showVariantError={showVariantError}
                inStock={isProductInStock}
                onWaitlistClick={() => setShowWaitlistModal(true)}
            />

            {/* Waitlist Modal */}
            {showWaitlistModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-brand-charcoal p-6 rounded-lg max-w-md w-full mx-4">
                        <h3 className="text-xl font-semibold mb-4 text-black dark:text-white">
                            Join the Waitlist
                        </h3>
                        <p className="mb-4 text-black dark:text-white">
                            We'll notify you when {product.name} is back in stock.
                        </p>
                        <form onSubmit={handleWaitlistSubmit} className="space-y-4">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                className="w-full p-2 border rounded dark:bg-brand-charcoal dark:border-gray-600"
                                required
                            />
                            <div className="flex space-x-2">
                                <button
                                    type="submit"
                                    className="bg-brand-blue text-white py-2 px-4 rounded hover:bg-blue-600"
                                >
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowWaitlistModal(false)}
                                    className="bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Tags Section */}
            <div className="flex flex-wrap gap-2">
                <MaterialTag />
                <span className="px-3 py-1 text-sm bg-gray-200 dark:bg-brand-charcoal text-black dark:text-white rounded-full">
                    {product.category}
                </span>
            </div>

            {/* Description Label */}
            <h2 className="text-2xl font-semibold text-black dark:text-white">
                Description:
            </h2>
            {/* Description */}
            <div className="space-y-2">
                <p className="text-black dark:text-white">
                    {product.description}
                </p>
            </div>
        </div>
    );
};

export default ProductInfo; 