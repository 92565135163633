import React from 'react';
import HeroSection from '../components/sections/HeroSection';
import AboutSection from '../components/sections/AboutSection';
import LatestProducts from '../components/sections/LatestProducts';
import Categories from '../components/sections/Home-page-categories';
import SEO from '../components/SEO';
import OrganizationSchema from '../components/OrganizationSchema';
{/*import Testimonials from '../components/sections/Testimonials';*/}

const Home: React.FC = () => {
  return (
    <>
      <SEO 
        title="Home"
        description="Discover unique contemporary jewelry pieces handcrafted with passion. Our collection features innovative designs in sterling silver, gold plating, and mixed metals, perfect for those seeking distinctive artistic expression."
      />
      <OrganizationSchema />
      <div className='relative'>
        <div className="container max-w-[80vw] mx-auto">
          <HeroSection />
          <LatestProducts />
          <Categories />
        </div>
        <div className="w-full">
        {/*<Testimonials />*/}
          <AboutSection />      
        </div>
      </div>
    </>
  );
};

export default Home;
