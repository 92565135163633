import { Product } from '../types/product-variant';

interface ProductSchemaProps {
  product: Product;
  currentUrl: string;
}

const ProductSchema: React.FC<ProductSchemaProps> = ({ product, currentUrl }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": product.name,
    "description": product.description,
    "image": [
      product.mainImage,
      product.secondaryImage,
      product.thirdImage
    ].filter(Boolean), // Remove undefined values
    "url": currentUrl,
    "sku": product.id,
    "brand": {
      "@type": "Brand",
      "name": "Camila Parente"
    },
    "offers": {
      "@type": "Offer",
      "url": currentUrl,
      "priceCurrency": "EUR",
      "price": product.variants ? product.basePrice : product.price,
      "itemCondition": "https://schema.org/NewCondition",
      "availability": "https://schema.org/InStock"
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default ProductSchema;