type ShopState = {
    filters: {
      sortBy: string;
      category: string;
      collection: string;
      showOneOfKind: boolean | null;
    };
    currentPage: number;
    scrollPosition: number;
  };
  
  let shopState: ShopState = {
    filters: {
      sortBy: 'newest',
      category: 'all',
      collection: 'all',
      showOneOfKind: null
    },
    currentPage: 1,
    scrollPosition: 0
  };
  
  // Add helper function to validate category
  const isValidCategory = (category: string): boolean => {
    return category === 'all' || 
           category === 'Other' ||
           ["Earrings and Hoops", "Rings"].includes(category);
  };
  
  export const getShopState = () => shopState;
  
  export const setShopState = (newState: Partial<ShopState>) => {
    // Validate category if it's being updated
    if (newState.filters?.category && !isValidCategory(newState.filters.category)) {
      newState.filters.category = 'all';
    }
    shopState = { ...shopState, ...newState };
  };