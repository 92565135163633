// src/components/ProductCard.tsx
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../context/CartContext';
;

interface ProductCardProps {
  id: string;
  name: string;
  price: number;
  image: string;
  quantity: number;
  oneOfKind?: boolean;
  slug: string;
}

const ProductCard: React.FC<ProductCardProps> = ({ 
  id, 
  name, 
  price, 
  image, 
  quantity, 
  oneOfKind,
  slug
}) => {
  const { updateQuantity, removeItem } = useCart();
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const removalTimeout = useRef<NodeJS.Timeout>();
  
  const handleQuantityChange = (newQuantity: number) => {
    // Immediately update local state
    const validQuantity = Math.max(0, newQuantity);
    setLocalQuantity(validQuantity);
    
    if (validQuantity === 0) {
      if (removalTimeout.current) {
        clearTimeout(removalTimeout.current);
      }
      removalTimeout.current = setTimeout(() => {
        removeItem(id);
      }, 800); // Reduced grace period
    } else {
      if (removalTimeout.current) {
        clearTimeout(removalTimeout.current);
      }
      updateQuantity(id, validQuantity);
    }
  };

  useEffect(() => {
    // Cleanup timeout on unmount
    return () => {
      if (removalTimeout.current) {
        clearTimeout(removalTimeout.current);
      }
    };
  }, []);

  const QuantitySelector = () => {
  
    return (
      <div className="flex items-center gap-2 quantity-controls">
        <button
          onClick={() => handleQuantityChange(localQuantity - 1)}
          className="px-2 py-1 bg-gray-200 dark:bg-brand-charcoal rounded"
        >
          -
        </button>
        <span className="text-black dark:text-white">
          {localQuantity}
        </span>
        <button
          onClick={() => handleQuantityChange(localQuantity + 1)}
          className="px-2 py-1 bg-gray-200 dark:bg-brand-charcoal rounded"
        >
          +
        </button>
      </div>
    );
  };

  return (
    <div className="flex items-center gap-4 p-4 bg-white dark:bg-brand-dark rounded-lg shadow-md">
      <div className="relative flex-shrink-0">
        <Link 
          to={`/product/${slug}`} 
          className="product-link hover:opacity-75 transition-opacity"
        >
          <img src={image} alt={name} className="w-20 h-20 object-cover rounded-md" />
          {oneOfKind && (
            <div className="absolute top-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-2 py-1 rounded-full text-xs flex items-center gap-1">
              <FontAwesomeIcon icon={faStar} className="h-3 w-3" />
            </div>
          )}
        </Link>
      </div>
      <div className="flex-grow">
        <Link 
          to={`/product/${slug}`} 
          className="product-link hover:text-brand-blue transition-colors"
        >
          <h3 className="text-lg font-semibold text-black dark:text-white">{name}</h3>
        </Link>
        <div className="flex items-center justify-between mt-2">
          <div className="space-y-2">
            <p className="text-black dark:text-white">€{price.toFixed(2)}</p>
            {!oneOfKind && (
              <QuantitySelector />
            )}
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => removeItem(id)}
            className="remove-button text-red-500"
          >
            Remove
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;