import React, { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import products from '../../data/products.json';
import { Product } from '../../types/product-variant';

const LatestProducts: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const typedProducts = products as Product[];
    
    // Add resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    // Use useMemo to prevent recalculating on every render
    const randomProducts = useMemo(() => {
        // Create a copy of the products array to avoid mutating the original
        const shuffled = [...typedProducts]
            // Fisher-Yates shuffle algorithm
            .sort(() => Math.random() - 0.5)
            // Take first 4 items on mobile, 8 on desktop
            .slice(0, isMobile ? 4 : 8);

        // Sort the selected products to ensure a mix of collections
        return shuffled.sort((a, b) => {
            // First sort by collection
            const collectionA = a.collection?.toLowerCase() || '';
            const collectionB = b.collection?.toLowerCase() || '';
            if (collectionA !== collectionB) {
                return collectionA < collectionB ? -1 : 1;
            }
            
            // Then by category within each collection
            const categoryA = a.category?.toLowerCase() || '';
            const categoryB = b.category?.toLowerCase() || '';
            if (categoryA !== categoryB) {
                return categoryA < categoryB ? -1 : 1;
            }
            
            return 0;
        });
    }, [typedProducts, isMobile]); // Add isMobile to dependencies
    
    return (
        <div className="latest-products -mt-10 md:py-8 mb-20">
            <h2 className="text-3xl md:text-5xl font-bold text-center mb-8 text-dark dark:text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.3)]">
                Featured Products:
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-10">
                {randomProducts.map(product => (
                    <motion.div
                        key={product.id}
                        whileHover={{ scale: 1.05 }}
                        className="bg-white dark:bg-brand-charcoal rounded-lg shadow-md overflow-hidden"
                    >
                        <Link to={`/product/${product.slug}`}>
                            <div className="relative">
                                <img
                                    src={product.defaultImages?.main ?? product.mainImage}
                                    alt={product.name}
                                    className="w-full h-64 object-cover"
                                />
                                {product.oneOfKind && (
                                    <div className="absolute top-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm flex items-center gap-1">
                                        <FontAwesomeIcon icon={faStar} className="h-3 w-3" />
                                        <span>One of a Kind</span>
                                    </div>
                                )}
                                {product.variants && (
                                    <div className="absolute bottom-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm">
                                        Multiple Options
                                    </div>
                                )}
                            </div>
                            <div className="p-4">
                                <h3 className="text-xl font-semibold text-brand-dark dark:text-white mb-2">
                                    {product.name}
                                </h3>
                                <p className="text-brand-dark dark:text-white">
                                    {product.variants 
                                        ? `From €${product.basePrice?.toFixed(2)}`
                                        : `€${product.price?.toFixed(2)}`
                                    }
                                </p>
                            </div>
                        </Link>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default LatestProducts;