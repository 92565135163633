import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Shop from './pages/shop';
import Cart from './pages/cart';
import Header from './components/Header';
import Layout from './layouts/layout';
import Footer from './components/Footer';
import ProductPage from './pages/product';
import Legal from './pages/legal';
import ScrollToTop from './functions/ScrollToTop';
import { CartProvider } from './context/CartContext';
import { HelmetProvider } from 'react-helmet-async';
import CategoryPageWrapper from './pages/categories/[category]';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Success from './pages/success';
import { UIProvider } from './context/UIContext';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <CartProvider>
        <UIProvider>
          <div className="flex flex-col min-h-screen overflow-x-hidden">
            <Router>
              <ScrollToTop />
              <Header />
              <Layout>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/shop" element={<Shop />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/product/:slug" element={<ProductPage />} />
                  <Route path="/legal" element={<Legal />} />
                  <Route path="/categories/:category" element={<CategoryPageWrapper />} />
                  <Route path="/success" element={<Success />} />
                </Routes>
              </Layout>
              <Footer />
            </Router>
            <SpeedInsights />
          </div>
        </UIProvider>
      </CartProvider>
    </HelmetProvider>
  );
};

export default App;