import React, { useState, useMemo, useEffect } from 'react';
import products from '../data/products.json';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useSearchParams } from 'react-router-dom';
import { faStar, faArrowDownWideShort, faFilter, faXmark, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getShopState, setShopState } from '../context/shopState';
import SEO from '../components/SEO';


type FilterState = {
  sortBy: string;
  category: string;
  collection: string;
  showOneOfKind: boolean | null;
};

const ITEMS_PER_PAGE = 12;

// Add this helper function at the top of the file, before the Shop component
const mapCategory = (category: string): string => {
  // Normalize the category string
  const normalized = category.toLowerCase().trim();
  
  // Handle earring variations
  if (normalized.includes('earring') || normalized.includes('hoop')) {
    return 'Earrings and Hoops';
  }
  
  // Handle ring variations
  if (normalized.includes('ring')) {
    return 'Rings';
  }
  
  // Group all other categories under "Other"
  return 'Other';
};

const Shop: React.FC = () => {
  const [searchParams] = useSearchParams();
  
  // Initialize state from URL params or stored values
  const [filters, setFilters] = useState<FilterState>(() => {
    const categoryParam = searchParams.get('category');
    const collectionParam = searchParams.get('collection');
    const oneOfKindParam = searchParams.get('oneOfKind');
    
    return {
      sortBy: getShopState().filters.sortBy,
      category: categoryParam || getShopState().filters.category,
      collection: collectionParam || getShopState().filters.collection,
      showOneOfKind: oneOfKindParam ? oneOfKindParam === 'true' : getShopState().filters.showOneOfKind
    };
  });
  const [currentPage, setCurrentPage] = useState(getShopState().currentPage);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

  // Add this near the top of your Shop component to see what categories exist
  useEffect(() => {
    console.log('All categories:', [...new Set(products.map(p => p.category))]);
    console.log('Mapped categories:', [...new Set(products.map(p => mapCategory(p.category)))]);
  }, []);

  // Get filtered products without sorting
  const getFilteredProducts = (currentFilters: FilterState) => {
    return products.filter(product => {
      const mappedCategory = mapCategory(product.category);
      
      // Handle category filtering
      if (currentFilters.category !== 'all' && mappedCategory !== currentFilters.category) {
        return false;
      }

      // Collection filter
      if (currentFilters.collection !== 'all' && product.collection !== currentFilters.collection) {
        return false;
      }

      // One of Kind filter
      if (currentFilters.showOneOfKind !== null && product.oneOfKind !== currentFilters.showOneOfKind) {
        return false;
      }

      return true;
    });
  };

  // Get available options based on current filters
  const getAvailableOptions = () => {
    const tempFilters = { ...filters };
    const forCategories = getFilteredProducts({ ...tempFilters, category: 'all' });
    
    // Define the simplified categories
    const mainCategories = ["Earrings and Hoops", "Rings", "Other"];
    const availableCategories = mainCategories.filter(category =>
      forCategories.some(product => mapCategory(product.category) === category)
    );

    // Check if there are any products that don't fit in main categories
    const otherItems = forCategories.filter(product => {
      const mappedCategory = mapCategory(product.category);
      return !mainCategories.includes(mappedCategory);
    });

    if (otherItems.length > 0) {
      availableCategories.push("Other");
    }

    // Get collections
    const forCollections = getFilteredProducts({ ...tempFilters, collection: 'all' });
    const availableCollections = [...new Set(forCollections.map(p => p.collection))];

    // Check one-of-kind status
    const forOneOfKind = getFilteredProducts({ ...tempFilters, showOneOfKind: null });
    const hasOneOfKind = forOneOfKind.some(p => p.oneOfKind);
    const hasLimitedSeries = forOneOfKind.some(p => !p.oneOfKind);

    return {
      categories: availableCategories,
      collections: availableCollections,
      hasOneOfKind,
      hasLimitedSeries
    };
  };

  const { categories, collections, hasOneOfKind, hasLimitedSeries } = getAvailableOptions();

  // Filter and sort products
  const filteredProducts = useMemo(() => {
    let filtered = products.filter(product => {
      const mappedCategory = mapCategory(product.category);
      
      // Category filter
      if (filters.category !== 'all' && mappedCategory !== filters.category) {
        return false;
      }

      // Collection filter
      if (filters.collection !== 'all' && product.collection !== filters.collection) {
        return false;
      }

      // One of Kind filter
      if (filters.showOneOfKind !== null && product.oneOfKind !== filters.showOneOfKind) {
        return false;
      }

      return true;
    });

    // Sort products
    switch (filters.sortBy) {
      case 'collection-asc':
        return filtered.sort((a, b) => 
          (a.collection || '').localeCompare(b.collection || '')
        );
      case 'collection-desc':
        return filtered.sort((a, b) => 
          (b.collection || '').localeCompare(a.collection || '')
        );
      case 'price-asc':
        return filtered.sort((a, b) => {
          // Get minimum price for products with variants
          const priceA = a.variants 
            ? Math.min(...a.variants.map(v => v.price))
            : a.price || 0;
          const priceB = b.variants 
            ? Math.min(...b.variants.map(v => v.price))
            : b.price || 0;
          return priceA - priceB;
        });
      case 'price-desc':
        return filtered.sort((a, b) => {
          // Get maximum price for products with variants
          const priceA = a.variants 
            ? Math.max(...a.variants.map(v => v.price))
            : a.price || 0;
          const priceB = b.variants 
            ? Math.max(...b.variants.map(v => v.price))
            : b.price || 0;
          return priceB - priceA;
        });
      case 'alpha-asc':
        return filtered.sort((a, b) => 
          (a.name || '').localeCompare(b.name || '')
        );
      case 'alpha-desc':
        return filtered.sort((a, b) => 
          (b.name || '').localeCompare(a.name || '')
        );
      case 'newest':
      default:
        return filtered.sort((a, b) => b.id.localeCompare(a.id));
    }
    return filtered;
  }, [filters, products]);

  // Calculate pagination
  const totalPages = Math.ceil(filteredProducts.length / ITEMS_PER_PAGE);
  const currentProducts = filteredProducts.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Reset to first page when filters change
  React.useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    // Smooth scroll to top of products grid
    document.querySelector('.products-grid')?.scrollIntoView({ behavior: 'smooth' });
  };

  // Add useEffect for body scroll lock
  useEffect(() => {
    if (isMobileFiltersOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileFiltersOpen]);

  // Add effect to save state
  useEffect(() => {
    setShopState({
      filters,
      currentPage,
      scrollPosition: window.scrollY
    });
  }, [filters, currentPage]);

  // Restore scroll position on mount
  useEffect(() => {
    const savedPosition = getShopState().scrollPosition;
    if (savedPosition) {
      window.scrollTo(0, savedPosition);
    }
  }, []);

  // Add this function near your other handlers
  const clearFilters = () => {
    setFilters({
      sortBy: 'newest',
      category: 'all',
      collection: 'all',
      showOneOfKind: null
    });
    setCurrentPage(1);
  };

  return (
    <>
      <SEO 
        title="Shop"
        description="Browse our collection of handcrafted contemporary jewelry. From statement earrings to unique pendants, find pieces that reflect your individual style and artistic taste."
      />
      <div className="container mx-auto px-4 py-8 max-w-[90vw]">
        {/* Hero Section */}
        <div className="flex justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-black text-center mb-10 dark:text-white p-4">
            Discover our handcrafted collections:
          </h1>
        </div>

        {/* Mobile Filter Button */}
        <div className="md:hidden flex justify-between items-center mb-4">
          <button
            onClick={() => setIsMobileFiltersOpen(true)}
            className="flex items-center gap-2 px-4 py-2 rounded-lg bg-black dark:bg-white text-white dark:text-black"
          >
            <FontAwesomeIcon icon={faFilter} />
            <span>Filters</span>
          </button>

          {/* Sort By - Always Visible */}
          <div className="flex items-center gap-2">
            <FontAwesomeIcon 
              icon={faArrowDownWideShort} 
              className="text-black dark:text-white h-4"
            />
            <select
              value={filters.sortBy}
              onChange={(e) => setFilters({ ...filters, sortBy: e.target.value })}
              className="text-sm px-2 py-1 rounded-lg bg-white dark:bg-dark border border-dark dark:border-white text-dark dark:text-black hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer"
            >
              <option value="newest">Newest</option>
              <option value="alpha-asc">Name: A to Z</option>
              <option value="alpha-desc">Name: Z to A</option>
              <option value="collection-asc">Collection: A to Z</option>
              <option value="collection-desc">Collection: Z to A</option>
              <option value="price-asc">Price: Low to High</option>
              <option value="price-desc">Price: High to Low</option>
            </select>
          </div>
        </div>

        {/* Mobile Filters Modal */}
        <AnimatePresence>
          {isMobileFiltersOpen && (
            <motion.div
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
              className="fixed inset-0 bg-white dark:bg-black z-50 md:hidden overflow-y-auto"
            >
              <div className="p-4">
                <div className="flex justify-between items-center mb-8">
                  <h2 className="text-xl font-bold text-dark dark:text-white">Filters</h2>
                  <button
                    onClick={() => setIsMobileFiltersOpen(false)}
                    className="text-dark dark:text-white"
                  >
                    <FontAwesomeIcon icon={faXmark} size="lg" />
                  </button>
                </div>

                <div className="space-y-6">
                  <div className="flex flex-col gap-4">
                    <select
                      value={filters.category}
                      onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                      className="w-full px-4 py-2 rounded-lg bg-gray-200 dark:bg-brand-charcoal text-dark dark:text-white"
                    >
                      <option value="all">All Categories</option>
                      {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                      ))}
                    </select>

                    <select
                      value={filters.collection}
                      onChange={(e) => setFilters({ ...filters, collection: e.target.value })}
                      className="w-full px-4 py-2 rounded-lg bg-gray-200 dark:bg-brand-charcoal text-dark dark:text-white"
                    >
                      <option value="all">All Collections</option>
                      {collections.map(collection => (
                        <option key={collection} value={collection}>{collection}</option>
                      ))}
                    </select>

                    <select
                      value={filters.showOneOfKind ? 'true' : filters.showOneOfKind === false ? 'false' : 'all'}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFilters({ 
                          ...filters, 
                          showOneOfKind: value === 'true' ? true : value === 'false' ? false : null 
                        });
                      }}
                      className="w-full px-4 py-2 rounded-lg bg-gray-200 dark:bg-brand-charcoal text-dark dark:text-white"
                    >
                      <option value="all">All Items</option>
                      {hasOneOfKind && <option value="true">One of a Kind</option>}
                      {hasLimitedSeries && <option value="false">Limited Series</option>}
                    </select>
                  </div>
                </div>

                {/* Mobile Filters Modal bottom buttons */}
                <div className="fixed bottom-0 left-0 right-0 p-4 bg-white dark:bg-brand-dark border-t border-gray-200 dark:border-brand-charcoal">
                  <div className="flex gap-4">
                    {(filters.category !== 'all' || 
                      filters.collection !== 'all' || 
                      filters.showOneOfKind !== null) ? (
                      <>
                        <button
                          onClick={clearFilters}
                          className="w-1/2 px-4 py-3 border border-brand-blue text-brand-blue rounded-lg hover:bg-brand-blue/10 transition-colors"
                        >
                          Clear Filters
                        </button>
                        <button
                          onClick={() => setIsMobileFiltersOpen(false)}
                          className="w-1/2 px-4 py-3 bg-brand-blue text-white rounded-lg hover:bg-brand-blue/90 transition-colors"
                        >
                          Apply Filters
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => setIsMobileFiltersOpen(false)}
                        className="w-full px-4 py-3 bg-brand-blue text-white rounded-lg hover:bg-brand-blue/90 transition-colors"
                      >
                        Apply Filters
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Desktop Filters */}
        <div className="hidden md:flex flex-wrap items-center justify-between mb-8">
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center gap-2">
              <FontAwesomeIcon 
                icon={faArrowDownWideShort} 
                className="text-brand-dark dark:text-brand-white h-4"
              />
              <select
                value={filters.sortBy}
                onChange={(e) => setFilters({ ...filters, sortBy: e.target.value })}
                className="px-6 py-3 rounded-lg bg-white dark:bg-brand-dark border border-brand-dark dark:border-brand-white text-brand-dark dark:text-brand-white hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer"
              >
                <option value="newest">Newest</option>
                <option value="alpha-asc">Name: A to Z</option>
                <option value="alpha-desc">Name: Z to A</option>
                <option value="collection-asc">Collection: A to Z</option>
                <option value="collection-desc">Collection: Z to A</option>
                <option value="price-asc">Price: Low to High</option>
                <option value="price-desc">Price: High to Low</option>
              </select>
            </div>

            <select
              value={filters.category}
              onChange={(e) => setFilters({ ...filters, category: e.target.value })}
              className="px-6 py-3 rounded-lg bg-white dark:bg-brand-dark border border-brand-dark dark:border-brand-white text-brand-dark dark:text-brand-white hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer"
            >
              <option value="all">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>

            <select
              value={filters.collection}
              onChange={(e) => setFilters({ ...filters, collection: e.target.value })}
              className="px-6 py-3 rounded-lg bg-white dark:bg-brand-dark border border-brand-dark dark:border-brand-white text-brand-dark dark:text-brand-white hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer"
            >
              <option value="all">All Collections</option>
              {collections.map(collection => (
                <option key={collection} value={collection}>{collection}</option>
              ))}
            </select>

            <select
              value={filters.showOneOfKind ? 'true' : filters.showOneOfKind === false ? 'false' : 'all'}
              onChange={(e) => {
                const value = e.target.value;
                setFilters({ 
                  ...filters, 
                  showOneOfKind: value === 'true' ? true : value === 'false' ? false : null 
                });
              }}
              className="px-6 py-3 rounded-lg bg-white dark:bg-brand-dark border border-brand-dark dark:border-brand-white text-brand-dark dark:text-brand-white hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer"
            >
              <option value="all">All Items</option>
              {hasOneOfKind && <option value="true">One of a Kind</option>}
              {hasLimitedSeries && <option value="false">Limited Series</option>}
            </select>
          </div>

          {/* Clear filters button */}
          {(filters.category !== 'all' || 
            filters.collection !== 'all' || 
            filters.showOneOfKind !== null) && (
            <button
              onClick={clearFilters}
              className="px-6 py-3 rounded-lg bg-white dark:bg-brand-dark border border-brand-dark dark:border-brand-white text-brand-dark dark:text-brand-white hover:border-brand-blue dark:hover:border-brand-blue transition-colors cursor-pointer mt-4 float-right"
            >
              Clear Filters
            </button>
          )}
        </div>

        {/* Products Grid */}
        <div className="relative w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] px-4">
          <div className="container mx-auto">
            {/* Add products count */}
            <p className="text-dark dark:text-white mb-4">
              Showing {currentProducts.length} of {filteredProducts.length} products
            </p>

            <div className="products-grid grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {currentProducts.map(product => (
                <motion.div
                  key={product.id}
                  whileHover={{ scale: 1.05 }}
                  className="bg-white dark:bg-brand-charcoal rounded-lg shadow-md overflow-hidden"
                >
                  <Link to={`/product/${product.slug}`}>
                    <div className="relative">
                      <img
                        src={product.defaultImages?.main ?? product.mainImage}
                        alt={product.name}
                        className="w-full h-64 object-cover"
                      />
                      {product.oneOfKind && (
                          <div className="absolute top-2 left-2 bg-brand-blue/80 backdrop-blur-sm text-white px-2 py-0.5 rounded-full text-xs flex items-center gap-1">
                          <FontAwesomeIcon icon={faStar} className="h-3 w-3" />
                          <span>One of a Kind</span>
                          </div>
                      )}
                      {product.variants && (
                        <div className="absolute bottom-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm">
                          Multiple Options
                        </div>
                      )}
                    </div>
                    <div className="p-4">
                      <h2 className="text-xl font-semibold text-dark dark:text-white mb-2">
                        {product.name}
                      </h2>
                      <p className="text-dark dark:text-white">
                        {product.variants ? (
                          <>From €{product.basePrice?.toFixed(2)}</>
                        ) : (
                          <>€{product.price?.toFixed(2)}</>
                        )}
                      </p>
                    </div>
                  </Link>
                </motion.div>
              ))}
            </div>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex justify-center items-center gap-2 mt-8">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`p-2 rounded-lg ${
                    currentPage === 1
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-dark dark:text-white hover:bg-brand-blue hover:text-white'
                  }`}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={`w-8 h-8 rounded-lg ${
                      currentPage === index + 1
                        ? 'bg-brand-blue text-white'
                        : 'text-dark dark:text-white hover:bg-brand-blue hover:text-white'
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`p-2 rounded-lg ${
                    currentPage === totalPages
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-dark dark:text-white hover:bg-brand-blue hover:text-white'
                  }`}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;