import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

const Success: React.FC = () => {
  const { clearCart } = useCart();
  const [isClearing, setIsClearing] = useState(true);

  useEffect(() => {
    // Add a slight delay before clearing the cart
    const timer = setTimeout(() => {
      clearCart();
      setIsClearing(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [clearCart]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-16 max-w-[80vw] text-center"
    >
      {isClearing ? (
        <FontAwesomeIcon 
          icon={faSpinner} 
          className="text-6xl text-brand-blue animate-spin mb-8" 
        />
      ) : (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 20 }}
        >
          <FontAwesomeIcon 
            icon={faCheckCircle} 
            className="text-6xl text-green-500 mb-8" 
          />
        </motion.div>
      )}

      <motion.h1 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
        className="text-4xl font-bold text-brand-dark dark:text-brand-white mb-6"
      >
        Thank you for your order!
      </motion.h1>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="max-w-md mx-auto"
      >
        <p className="text-brand-dark dark:text-brand-white mb-8 text-lg">
          We'll send you an email with your order details shortly. Your unique pieces will be crafted with care and shipped to you soon.
        </p>

        <Link
          to="/shop"
          className="inline-block bg-brand-blue text-white px-8 py-4 rounded-lg hover:bg-brand-dark transition-colors text-lg font-semibold"
        >
          Continue Shopping
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default Success;