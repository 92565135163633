const OrganizationSchema: React.FC = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Camila Parente",
    "url": "https://www.camilaparente.store",
    "logo": "https://www.camilaparente.store/Logo-Black-Simple.svg", // Add your logo URL
    "description": "Contemporary handcrafted jewelry by Camila Parente. Unique designs in sterling silver and gold plating.",
    "sameAs": [
      "https://www.instagram.com/camilamartinezparente/", // Add your social media URLs
      // Add other social media profiles
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer service",
      "email": "camila.mpp88@gmail.com" // Add your contact email
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default OrganizationSchema;