import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Product } from '../../types/product-variant';
import { useUI } from '../../context/UIContext';

interface CartActionsProps {
    product: Product;
    isAdded: boolean;
    localQuantity: number;
    onAddToCart: () => void;
    onQuantityChange: (quantity: number) => void;
    showVariantError: boolean;
    inStock?: boolean;
    onWaitlistClick?: () => void;
}

const CartActions = ({
    product,
    isAdded,
    localQuantity,
    onAddToCart,
    onQuantityChange,
    showVariantError,
    inStock = true,
    onWaitlistClick
}: CartActionsProps) => {
    const { isCartOpen } = useUI();

    const CartButton = () => {
        if (!inStock) {
            return (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onWaitlistClick}
                    className="w-full px-8 py-3 rounded-lg shadow-md flex items-center justify-center space-x-2 bg-gray-500 text-white"
                >
                    <span>Join Waitlist</span>
                </motion.button>
            );
        }

        return (
            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={onAddToCart}
                className={`w-full px-8 py-3 rounded-lg shadow-md flex items-center justify-center space-x-2 transition-all duration-300 ${
                    isAdded 
                        ? 'bg-white dark:bg-brand-dark border-2 border-brand-blue text-brand-blue shadow-brand-blue/20'
                        : 'bg-brand-blue text-white'
                }`}
            >
                <FontAwesomeIcon icon={faShoppingCart} />
                <span>{isAdded ? 'Added to Cart' : 'Add to Cart'}</span>
            </motion.button>
        );
    };

    const QuantitySelector = () => (
        <div className="flex items-center justify-center gap-4 mt-4">
            <button
                onClick={() => onQuantityChange(localQuantity - 1)}
                className="px-3 py-1 bg-gray-200 dark:bg-brand-charcoal rounded"
            >
                -
            </button>
            <span className="text-black dark:text-white">
                {localQuantity}
            </span>
            <button
                onClick={() => onQuantityChange(localQuantity + 1)}
                className="px-3 py-1 bg-gray-200 dark:bg-brand-charcoal rounded"
            >
                +
            </button>
        </div>
    );

    return (
        <>
            {/* Top Mobile Add to Cart Button */}
            <div className="md:hidden">
                <CartButton />
                {isAdded && !product?.oneOfKind && <QuantitySelector />}
            </div>

            {/* Desktop Add to Cart Button */}
            <div className="hidden md:block">
                <CartButton />
                {isAdded && !product?.oneOfKind && <QuantitySelector />}
            </div>

            {/* Fixed Bottom Mobile Add to Cart Button */}
            <div className={`
                md:hidden fixed bottom-0 left-0 right-0 p-4 
                bg-white dark:bg-brand-dark 
                border-t border-gray-200 dark:border-brand-charcoal 
                z-50 transition-transform duration-300
                ${isCartOpen ? 'translate-y-full' : 'translate-y-0'}
            `}>
                <CartButton />
                {isAdded && !product?.oneOfKind && <QuantitySelector />}
            </div>

            {/* Error message for variant selection */}
            {showVariantError && (
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg"
                >
                    Please select a material option before adding to cart
                </motion.div>
            )}
        </>
    );
};

export default CartActions; 