import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Link } from 'react-router-dom';

interface GridItemProps {
  text: string;
  imageSrc: string;
  order: number;
}

const GridItem: React.FC<GridItemProps> = ({ text, imageSrc }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10"
    >
      <div className="h-64 overflow-hidden">
        <img 
          src={imageSrc} 
          alt="Jewelry crafting process" 
          className="w-full h-full object-cover hover:scale-105 transition-transform duration-500"
        />
      </div>
      <div className="p-6">
        <p className="text-lg text-black/90 dark:text-white/80 leading-relaxed">
          {text}
        </p>
      </div>
    </motion.div>
  );
};

const AboutSection: React.FC = () => {
  const controls = useAnimation();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start('visible');
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [ref, controls]);

  const gridItemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const gridItems = [
    {
      text: "Our handcrafted jewelry celebrates the beauty of imperfection and the power of nature. Blending traditional craftsmanship with modern designs, we create pieces that are simultaneously timeless and innovative.",
      imageSrc: "/img_7860-24-2.jpg",
      order: 1
    },
    {
      text: "Rooted in the rich jewelry-making heritage of Mexico, our brand draws inspiration from the organic textures and forms found in the natural world. Each item is meticulously handmade, with a focus on quality materials and intricate detailing that elevates the everyday into the extraordinary.",
      imageSrc: "/aretes-20rebote-20radiografia-20al-20fondo.jpg",
      order: 2
    },
    {
      text: "By honoring the imperfect beauty of nature, we invite our customers to embrace their own unique styles and expressions. Our jewelry is not just an accessory, but a canvas for self-discovery and a reflection of the wearer's individuality.",
      imageSrc: "/3.1-20bassa.jpg",
      order: 3
    },
    {
      text: "Beyond aesthetics, we are deeply committed to ethical and sustainable practices. All of our materials are responsibly sourced, ensuring that every piece you wear carries a positive impact.",
      imageSrc: "/1a8d4f46-204c-46d7-a44f-c30389d3c941-20-1-.jpg",
      order: 4
    }
  ];

  return (
    <div ref={setRef} className="brand-context relative py-20 w-screen">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-brand-gradient bg-opacity-50 dark:bg-opacity-100">
        {/* Decorative elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-20 -left-20 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          <div className="absolute top-40 right-20 w-80 h-80 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          <div className="absolute bottom-20 left-1/3 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
        </div>
      </div>

      {/* Content container */}
      <div className="relative">
        <div className="container mx-auto px-4">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-12"
          >
            <h2 className="text-4xl font-bold text-black mb-4">
              About the Brand
            </h2>
            <p className="text-lg text-black/80 max-w-2xl mx-auto">
              Crafting unique pieces with passion and purpose
            </p>
          </motion.div>

          {/* Grid container with max-width matching product grid */}
          <div className="max-w-[80vw] mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 auto-rows-auto">
              {gridItems.map((item, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  animate={controls}
                  variants={gridItemVariants}
                  className={`${index === 0 || index === 3 ? 'md:col-span-2' : ''}`}
                >
                  <GridItem text={item.text} imageSrc={item.imageSrc} order={item.order} />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="text-center mt-12 flex justify-center gap-4">
            <Link
              to="/about"
              className="inline-block bg-black/10 backdrop-blur-md text-black border border-white/20 px-8 py-3 rounded-xl hover:bg-white/20 transition-colors duration-300"
            >
              Learn More
            </Link>
            <Link
              to="/shop"
              className="inline-block bg-white text-black border border-white px-8 py-3 rounded-xl hover:bg-white/90 transition-colors duration-300"
            >
              Shop Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;