import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, } from '@fortawesome/free-solid-svg-icons';
import { motion,  } from 'framer-motion';
import products from '../data/products.json';
import { Product } from '../types/product-variant';
import { categoryContent } from '../data/categoryContent';
import SEO from '../components/SEO';

interface CategoryPageProps {
  title: string;
  description: string;
  categoryFilter: string[];
  heroImage: string;
}

const CategoryPage: React.FC<CategoryPageProps> = ({
  title,
  description,
  categoryFilter,
  heroImage
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const typedProducts = products as Product[];

  const videoRef = useRef<HTMLVideoElement[]>([]);
  const videoObserverRef = useRef<IntersectionObserver | null>(null);
  const [videoLoading, setVideoLoading] = useState<{[key: number]: boolean}>({});

  const currentCategoryContent = categoryContent[title.toLowerCase().replace(/ /g, '-')];

  useEffect(() => {
    // Cleanup previous observer
    if (videoObserverRef.current) {
      videoObserverRef.current.disconnect();
    }

    // Create new intersection observer
    videoObserverRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target as HTMLVideoElement;
        
        if (entry.isIntersecting) {
          // When video comes into view
          video.preload = 'auto'; // Start loading the full video
          video.play().catch(error => {
            console.warn('Video autoplay failed:', error);
          });
        } else {
          // When video is out of view
          video.pause();
          if (!video.currentTime) {
            video.preload = 'none'; // Stop loading if video hasn't started
          }
        }
      });
    }, {
      root: null,
      rootMargin: '50px',
      threshold: 0.1
    });

    // Observe all videos
    videoRef.current.forEach(video => {
      if (video && videoObserverRef.current) {
        videoObserverRef.current.observe(video);
      }
    });

    // Cleanup
    return () => {
      if (videoObserverRef.current) {
        videoObserverRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const categoryProducts = [...typedProducts]
    .filter(p => {
      const isIncluded = categoryFilter.includes(p.category);
      console.log(`Product: ${p.name}, Category: ${p.category}, Included: ${isIncluded}`);
      return isIncluded;
    })
    .sort(() => Math.random() - 0.5)
    .slice(0, isMobile ? 2 : 4);

  // First, get the static images
  const staticImages = (currentCategoryContent.additionalHeroImages || []).map((src, index) => ({
    src,
    id: `static-hero-${index}`
  }));

  // Get ALL product images, excluding Granchi main image for unique finds
  const productImages = typedProducts
    .filter(p => categoryFilter.includes(p.category))
    .flatMap(p => {
      // Skip the Granchi main image for unique finds category
      if (title.toLowerCase() === 'unique finds' && p.slug?.includes('granchi')) {
        return [
          { src: p.secondaryImage, id: `${p.id}-secondary` },
          { src: p.thirdImage, id: `${p.id}-third` },
          ...(p.defaultImages ? [
            { src: p.defaultImages.model, id: `${p.id}-default-model` },
            { src: p.defaultImages.zoom, id: `${p.id}-default-zoom` }
          ] : [])
        ];
      }
      
      // For all other products, include all images
      return [
        { src: p.mainImage, id: `${p.id}-main` },
        { src: p.secondaryImage, id: `${p.id}-secondary` },
        { src: p.thirdImage, id: `${p.id}-third` },
        ...(p.defaultImages ? [
          { src: p.defaultImages.main, id: `${p.id}-default-main` },
          { src: p.defaultImages.model, id: `${p.id}-default-model` },
          { src: p.defaultImages.zoom, id: `${p.id}-default-zoom` }
        ] : [])
      ];
    })
    .filter(img => img.src) // Remove undefined images
    .sort(() => Math.random() - 0.5); // Randomize the order

  // Combine them, prioritizing static images but allowing fallback to any product image
  const allAvailableImages = [
    ...staticImages,
    ...productImages
  ].slice(0, 3); // Get first 3 images

  // If we still don't have enough images, use the hero image to fill remaining slots
  while (allAvailableImages.length < 3) {
    allAvailableImages.push({
      src: heroImage,
      id: `hero-fallback-${allAvailableImages.length}`
    });
  }

  const handleVideoLoadStart = (index: number) => {
    setVideoLoading(prev => ({ ...prev, [index]: true }));
  };

  const handleVideoCanPlay = (index: number) => {
    setVideoLoading(prev => ({ ...prev, [index]: false }));
  };

  const getSEODescription = () => {
    const category = title.toLowerCase();
    if (category.includes('earrings') || category.includes('hoops')) {
      return "Explore our contemporary earrings and hoops collection. Handcrafted sterling silver jewelry featuring architectural designs, geometric patterns, and artistic expressions. Each piece balances modern aesthetics with comfortable wear.";
    } else if (category.includes('rings')) {
      return "Discover our unique ring collection. Handcrafted sterling silver rings featuring sculptural designs, organic forms, and artistic details. Each piece combines contemporary style with traditional craftsmanship.";
    } else if (category.includes('unique')) {
      return "Browse our exclusive collection of unique jewelry pieces. One-of-a-kind designs featuring innovative techniques, mixed metals, and artistic expression. Each piece represents the frontier of contemporary jewelry design.";
    }
    return description;
  };

  return (
    <>
      <SEO 
        title={title}
        description={getSEODescription()}
        image={heroImage}
        type="website"
      />
      <div className="relative w-screen min-h-screen">
        {/* Background with gradient overlay */}
        <div className="absolute inset-0 bg-brand-blue bg-opacity-50 dark:bg-opacity-100">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-20 -left-20 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute top-40 right-20 w-80 h-80 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute bottom-20 left-1/3 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          </div>
        </div>

        <div className="relative">
          {/* Hero Section Container */}
          <div className="relative -mt-[96px] overflow-hidden">
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
              className="relative h-[60vh] w-full"
            >
              {/* Image Collage */}
              <div className="absolute inset-0 grid grid-cols-3 grid-rows-1">
                <div className="h-full">
                  <img 
                    src={heroImage} 
                    alt={`${title} 1`}
                    className="w-full h-full object-cover"
                    width={600}
                    height={600}
                  />
                </div>
                <div className="h-full grid grid-rows-2">
                  <img 
                    src={allAvailableImages[0]?.src || heroImage} 
                    alt={`${title} 2`}
                    className="w-full h-full object-cover"
                    width={600}
                    height={300}
                  />
                  <img 
                    src={allAvailableImages[1]?.src || heroImage} 
                    alt={`${title} 3`}
                    className="w-full h-full object-cover"
                    width={600}
                    height={300}
                  />
                </div>
                <div className="h-full">
                  <img 
                    src={allAvailableImages[2]?.src || heroImage} 
                    alt={`${title} 4`}
                    className="w-full h-full object-cover"
                    width={600}
                    height={600}
                  />
                </div>
              </div>

              {/* Gradient Overlays */}
              <div className="absolute inset-0 bg-gradient-to-b from-white/70 via-white/50 via-40% to-black/80 dark:from-black/50 dark:via-transparent dark:to-black/70" />
              
              {/* Content */}
              <div className="absolute inset-0 flex items-end">
                <div className="max-w-[80vw] mx-auto px-4 py-12 text-white">
                  <motion.h1 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="text-4xl md:text-5xl font-bold mb-4
                      drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)]
                      dark:drop-shadow-[0_2px_4px_rgba(0,0,0,0.4)]"
                  >
                    {title}
                  </motion.h1>
                  <motion.p 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.3 }}
                    className="text-xl md:text-2xl max-w-2xl
                      drop-shadow-[0_2px_4px_rgba(0,0,0,0.4)]
                      dark:drop-shadow-[0_2px_4px_rgba(0,0,0,0.5)]"
                  >
                    {description}
                  </motion.p>
                </div>
              </div>
            </motion.div>
          </div>

          <div className="max-w-[80vw] mx-auto py-20 px-4">
            {/* Category Specific Text */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="mb-20"
            >
              {currentCategoryContent.detailedDescription.split('\n').map((paragraph, index) => (
                <motion.p 
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 + (index * 0.1) }}
                  className="text-lg md:text-xl text-black/80 dark:text-white/80 leading-relaxed mb-6"
                >
                  {paragraph.trim()}
                </motion.p>
              ))}
            </motion.div>

            {/* Process Section */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className="mb-20"
            >
              <h2 className="text-3xl font-bold mb-12 text-black dark:text-white">Our Process</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {currentCategoryContent.processMedia.map((media, index) => (
                  <motion.div 
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.8 + (index * 0.1) }}
                    className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10"
                  >
                    {media.type === 'video' ? (
                      <div className="relative w-full h-80">
                        {videoLoading[index] && (
                          <div className="absolute inset-0 flex items-center justify-center bg-black/10 backdrop-blur-sm">
                            <div className="w-8 h-8 border-4 border-brand-blue border-t-transparent rounded-full animate-spin"></div>
                          </div>
                        )}
                        <video
                          ref={el => {
                            if (el) videoRef.current[index] = el;
                          }}
                          key={`${title}-${media.src}`}
                          muted
                          playsInline
                          loop
                          preload="metadata"
                          className="w-full h-80 object-cover"
                          onLoadStart={() => handleVideoLoadStart(index)}
                          onCanPlay={() => handleVideoCanPlay(index)}
                        >
                          <source src={media.src} type="video/webm" />
                          <source src={media.src.replace('.webm', '.mp4')} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    ) : (
                      <img 
                        src={media.src} 
                        alt={media.description} 
                        className="w-full h-80 object-cover"
                        loading="lazy"
                        width={600}
                        height={320}
                      />
                    )}
                    <p className="p-6 text-lg md:text-xl text-black/80 dark:text-white/80 text-center font-medium">
                      {media.description}
                    </p>
                  </motion.div>
                ))}
              </div>
              <div className="mt-12 space-y-6">
                {currentCategoryContent.processText.map((text, index) => {
                  const parts = text.split(/(\*\*.*?\*\*)/g);
                  
                  return (
                    <motion.div 
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.6, delay: 1 + (index * 0.1) }}
                      className="flex items-start gap-4 text-lg md:text-xl text-black/80 dark:text-white/80 leading-relaxed"
                    >
                      <span className="text-2xl leading-none mt-1">•</span>
                      <p>
                        {parts.map((part, partIndex) => {
                          if (part.startsWith('**') && part.endsWith('**')) {
                            return (
                              <span key={partIndex} className="font-bold">
                                {part.slice(2, -2)}
                              </span>
                            );
                          }
                          return <span key={partIndex}>{part}</span>;
                        })}
                      </p>
                    </motion.div>
                  );
                })}
              </div>
            </motion.div>

            {/* Featured Products */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 1.2 }}
              className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl border border-white/10 p-4 sm:p-8 md:p-12"
            >
              <h2 className="text-3xl font-bold mb-8 md:mb-12 text-center text-black dark:text-white">Featured Pieces</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6 lg:gap-8">
                {categoryProducts.map((product, index) => (
                  <motion.div
                    key={product.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 1.4 + (index * 0.1) }}
                    whileHover={{ scale: 1.05 }}
                    className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-xl overflow-hidden border border-white/10"
                  >
                    <Link 
                      to={`/product/${product.slug}`}
                      className="block h-full"
                    >
                      <div className="relative aspect-square">
                        <img
                          src={product.defaultImages?.main ?? product.mainImage}
                          alt={product.name}
                          className="w-full h-full object-cover"
                          loading="lazy"
                          width={300}
                          height={300}
                        />
                        {product.oneOfKind && (
                          <div className="absolute top-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm flex items-center gap-1">
                            <FontAwesomeIcon icon={faStar} className="h-3 w-3" />
                            <span>One of a Kind</span>
                          </div>
                        )}
                        {product.variants && (
                          <div className="absolute bottom-2 right-2 bg-brand-blue/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm">
                            Multiple Options
                          </div>
                        )}
                      </div>
                      <div className="p-4 sm:p-6">
                        <h3 className="text-xl font-semibold text-black dark:text-white mb-2">
                          {product.name}
                        </h3>
                        <p className="text-lg text-black dark:text-white">
                          {product.variants 
                            ? `From €${product.basePrice?.toFixed(2)}`
                            : `€${product.price?.toFixed(2)}`
                          }
                        </p>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 1.8 }}
                className="text-center mt-8 md:mt-12"
              >
                {categoryFilter.includes("Earrings") || categoryFilter.includes("Hoops") ? (
                  <Link
                    to="/shop?category=Earrings%20and%20Hoops"
                    className="inline-block bg-black text-white dark:bg-white dark:text-black px-8 py-3 rounded-full hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors duration-300"
                  >
                    Explore Earrings and Hoops
                  </Link>
                ) : categoryFilter.includes("Rings") ? (
                  <Link
                    to="/shop?category=Rings"
                    className="inline-block bg-black text-white dark:bg-white dark:text-black px-8 py-3 rounded-full hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors duration-300"
                  >
                    Explore Rings
                  </Link>
                ) : (
                  <Link
                    to="/shop?category=Other"
                    className="inline-block bg-black text-white dark:bg-white dark:text-black px-8 py-3 rounded-full hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors duration-300"
                  >
                    Explore Other
                  </Link>
                )}
              </motion.div>
            </motion.div>

            {/* Bottom Categories Navigation */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 2.0 }}
              className="mt-20"
            >
              <div className="text-center mb-12">
                <h2 className="text-3xl md:text-4xl font-bold text-black dark:text-white mb-4">
                  Explore Other Categories
                </h2>
                <p className="text-lg text-black/80 dark:text-white/80">
                  Discover more pieces that speak to your style
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-0 w-full max-w-5xl mx-auto">
                {title !== "Unique Finds" && (
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Link 
                      to="/categories/unique-finds"
                      className="relative block"
                    >
                      <div className="h-80 md:h-[500px] w-full overflow-hidden relative">
                        <img
                          src={categoryContent['unique-finds'].heroImage}
                          alt="Unique Finds"
                          className="w-full h-full object-cover"
                          loading="lazy"
                          width={800}
                          height={500}
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-black/40 to-black/70 pt-20 pb-8 px-4 text-center text-white">
                        <h3 className="text-2xl font-bold">Unique Finds</h3>
                        <p className="mt-2">One-of-a-kind pieces for the extraordinary</p>
                      </div>
                    </Link>
                  </motion.div>
                )}
                {title !== "Earrings and Hoops" && (
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Link 
                      to="/categories/earrings-and-hoops"
                      className="relative block"
                    >
                      <div className="h-80 md:h-[500px] w-full overflow-hidden relative">
                        <img
                          src={categoryContent['earrings-and-hoops'].heroImage}
                          alt="Earrings and Hoops"
                          className="w-full h-full object-cover"
                          loading="lazy"
                          width={800}
                          height={500}
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-black/40 to-black/70 pt-20 pb-8 px-4 text-center text-white">
                        <h3 className="text-2xl font-bold">Earrings & Hoops</h3>
                        <p className="mt-2">Contemporary designs that move with you</p>
                      </div>
                    </Link>
                  </motion.div>
                )}
                {title !== "Rings" && (
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Link 
                      to="/categories/rings"
                      className="relative block"
                    >
                      <div className="h-80 md:h-[500px] w-full overflow-hidden relative">
                        <img
                          src={categoryContent['rings'].heroImage}
                          alt="Rings"
                          className="w-full h-full object-cover"
                          loading="lazy"
                          width={800}
                          height={500}
                        />
                      </div>
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-b from-transparent via-black/40 to-black/70 pt-20 pb-8 px-4 text-center text-white">
                        <h3 className="text-2xl font-bold">Rings</h3>
                        <p className="mt-2">Where stories take shape</p>
                      </div>
                    </Link>
                  </motion.div>
                )}
              </div>

              <div className="text-center mt-12">
                <Link
                  to="/contact"
                  className="inline-block bg-black text-white dark:bg-white dark:text-black px-8 py-3 rounded-full hover:bg-gray-800 dark:hover:bg-gray-200 transition-colors duration-300"
                >
                  Contact for Custom Work
                </Link>
              </div>
            </motion.div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryPage;