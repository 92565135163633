import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram,  faTiktok } from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';
import Logo from './Logo';

const Footer: React.FC = () => {
  return (
    <footer className="text-text-light dark:text-text-dark py-8 mt-auto">
      <div className="mx-auto px-4 max-w-[80vw]">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
          {/* Logo Section */}
          <div className="flex flex-col items-center md:items-start">
            <motion.div whileHover={{ scale: 1.1 }}>
              <Logo switchable={false} />
            </motion.div>
            <p className="text-sm text-text-dark dark:text-text-light mt-4">Joytias Concientes</p>
          </div>

          {/* Main Links Section */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4 text-text-dark dark:text-text-light">Quick Links</h3>
            <nav className="flex flex-col space-y-2 text-center md:text-left text-text-dark dark:text-text-light">
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/" className="transition-transform">Home</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/shop" className="transition-transform">Shop</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/about" className="transition-transform">About</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/contact" className="transition-transform">Contact</Link>
              </motion.div>
            </nav>
          </div>

          {/* Collections Section */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4 text-text-dark dark:text-text-light">Categories</h3>
            <nav className="flex flex-col space-y-2 text-center md:text-left text-text-dark dark:text-text-light">
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/categories/unique-finds" className="transition-transform">Unique Finds</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/categories/earrings-and-hoops" className="transition-transform">Earrings & Hoops</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/categories/rings" className="transition-transform">Rings</Link>
              </motion.div>
            </nav>
          </div>

          {/* Social Links Section */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4 text-text-dark dark:text-text-light">Socials</h3>
            <div className="flex space-x-4 text-text-dark dark:text-text-light">
              <motion.a whileHover={{ scale: 1.1 }} href="https://instagram.com/camilamartinezparente" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="transition-transform">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </motion.a>
              <motion.a whileHover={{ scale: 1.1 }} href="https://www.tiktok.com/@joyitas.conscientes?_t=8s4VFDx4qrl&_r=1" target="_blank" rel="noopener noreferrer" aria-label="TikTok" className="transition-transform">
                <FontAwesomeIcon icon={faTiktok} size="lg" />
              </motion.a>
            </div>
          </div>

          {/* Legal Section */}
          <div className="flex flex-col items-center md:items-start">
            <h3 className="text-lg font-semibold mb-4 text-text-dark dark:text-text-light">Legal</h3>
            <nav className="flex flex-col space-y-2 text-text-dark dark:text-text-light text-center md:text-left">
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/legal#privacy" className="transition-transform">Privacy Policy</Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.1 }}>
                <Link to="/legal#terms" className="transition-transform">Terms & Conditions</Link>
              </motion.div>
            </nav>
          </div>
        </div>

        {/* Copyright Section */}
        <div className="mt-8 pt-8 border-t border-gray-700 text-center text-text-dark dark:text-text-light text-sm">
          <p>&copy; {new Date().getFullYear()} Camila Parente. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;