import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import SEO from '../components/SEO';

const Contact: React.FC = () => {

  return (
    <>
      <SEO 
        title="Contact"
        description="Get in touch with us for custom jewelry inquiries, collaboration opportunities, or questions about our handcrafted contemporary pieces."
      />
      <div className="relative w-screen min-h-screen">
        {/* Background with gradient overlay */}
        <div className="absolute inset-0 bg-brand-blue bg-opacity-50 dark:bg-opacity-100">
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-20 -left-20 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute top-40 right-20 w-80 h-80 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
            <div className="absolute bottom-20 left-1/3 w-60 h-60 bg-brand-blue/20 dark:bg-brand-blue/30 rounded-full blur-3xl" />
          </div>
        </div>

        {/* Content */}
        <div className="relative py-20 px-4">
          <div className="max-w-6xl mx-auto">
            {/* Hero Section */}
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center mb-16"
            >
              <h1 className="text-4xl md:text-5xl font-bold text-black dark:text-white mb-6">
                Let's Create Something Special
              </h1>
              <p className="text-xl text-black/80 dark:text-white/80 max-w-2xl mx-auto">
                Every piece of jewelry tells a unique story. Whether you're dreaming of a custom design 
                or have questions about our collections, I'm here to help bring your vision to life.
              </p>
            </motion.div>

            {/* Contact Cards */}
            <div className="grid md:grid-cols-2 gap-8">
              {/* Contact Information */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 p-8"
              >
                <h2 className="text-2xl font-semibold text-black dark:text-white mb-6">
                  Custom Creations & Inquiries
                </h2>
                <p className="text-black/80 dark:text-white/80 mb-8">
                  Looking for a one-of-a-kind piece? Let's discuss your ideas and create something 
                  truly special together. From initial concept to final creation, I'll guide you 
                  through every step of the journey.
                </p>
                
                <div className="space-y-4">
                  <a 
                    href="mailto:camila.mpp88@gmail.com"
                    className="flex items-center gap-3 text-black/80 dark:text-white/80 hover:text-black dark:hover:text-white transition-colors"
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5" />
                    camila.mpp88@gmail.com
                  </a>
                  <a 
                    href="https://instagram.com/camilamartinezparente"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 text-black/80 dark:text-white/80 hover:text-black dark:hover:text-white transition-colors"
                  >
                    <FontAwesomeIcon icon={faInstagram} className="w-5 h-5" />
                    @camilamartinezparente
                  </a>
                </div>
              </motion.div>

              {/* Instagram Feed */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="bg-white/10 dark:bg-brand-charcoal/40 backdrop-blur-md rounded-2xl overflow-hidden border border-white/10 p-8"
              >
                <h2 className="text-2xl font-semibold text-black dark:text-white mb-6">
                  Follow My Journey
                </h2>
                <p className="text-black/80 dark:text- white/80 mb-8">
                  Discover behind-the-scenes content and latest creations on Instagram:
                </p>
                <div className="aspect-square rounded-xl overflow-hidden bg-black/20">
                  <iframe 
                    src="https://www.instagram.com/camilamartinezparente/embed"
                    className="w-full h-full"
                    frameBorder="0"
                    scrolling="no"
                    allowTransparency={true}
                  ></iframe>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;