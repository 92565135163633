import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import CategoryPage from '../../components/CategoryPage';
import { categoryContent } from '../../data/categoryContent';
import SEO from '../../components/SEO';

const CategoryPageWrapper: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  
  if (!category || !categoryContent[category]) {
    return <Navigate to="/404" replace />;
  }

  const content = categoryContent[category];

  return (
    <>
      <SEO 
        title={content.title}
        description={content.description}
      />
      <CategoryPage {...content} />
    </>
  );
};

export default CategoryPageWrapper;
