import { useState, useEffect } from 'react';
import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface LightboxProps {
    selectedImage: string | null;
    images: string[];
    onClose: () => void;
}

const Lightbox = ({ selectedImage, images, onClose }: LightboxProps) => {
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);

    useEffect(() => {
        if (selectedImage) {
            setLightboxIndex(images.indexOf(selectedImage));
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [selectedImage, images]);

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (!selectedImage) return;
            
            switch (e.key) {
                case 'ArrowLeft':
                    setLightboxIndex(prev => 
                        prev === 0 ? images.length - 1 : prev - 1
                    );
                    break;
                case 'ArrowRight':
                    setLightboxIndex(prev => 
                        prev === images.length - 1 ? 0 : prev + 1
                    );
                    break;
                case 'Escape':
                    onClose();
                    break;
            }
        };

        if (selectedImage) {
            window.addEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedImage, images.length, onClose]);

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStart(e.targetTouches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        setTouchEnd(e.targetTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        const swipeThreshold = 50;
        const swipeDistance = touchStart - touchEnd;

        if (Math.abs(swipeDistance) > swipeThreshold) {
            if (swipeDistance > 0) {
                setLightboxIndex(prev => 
                    prev === images.length - 1 ? 0 : prev + 1
                );
            } else {
                setLightboxIndex(prev => 
                    prev === 0 ? images.length - 1 : prev - 1
                );
            }
        }
    };

    if (!selectedImage) return null;

    return (
        <AnimatePresence>
            <div 
                className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
                onClick={onClose}
            >
                <div className="relative max-w-4xl mx-4">
                    <button 
                        className="absolute -top-12 right-0 text-white text-2xl"
                        onClick={onClose}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                    <img 
                        src={images[lightboxIndex]} 
                        alt="Product detail" 
                        className="max-h-[80vh] w-auto"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onClick={(e) => e.stopPropagation()}
                    />

                    {/* Dots indicator */}
                    <div className="absolute -bottom-12 left-1/2 transform -translate-x-1/2 flex gap-2">
                        {images.map((_, index) => (
                            <button
                                key={index}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setLightboxIndex(index);
                                }}
                                className={`w-2 h-2 rounded-full ${
                                    index === lightboxIndex 
                                        ? 'bg-brand-blue' 
                                        : 'bg-white/50'
                                }`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </AnimatePresence>
    );
};

export default Lightbox; 